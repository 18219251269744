<template>
  <div class="combined_query container">
    <el-form ref="form" :model="form" label-width="105px">
      <el-collapse v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title" class="collapse_title"> 企业信息 </template>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="企业地区">
                <el-cascader
                  v-model="form.queryCompanyDto.location"
                  :options="areaOptions"
                  filterable
                  :props="{ value: 'value', label: 'name', children: 'city' }"
                  clearable
                ></el-cascader>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="成立时间">
                <el-date-picker
                  v-model="queryCompanyDtoTime"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="注册资金">
                <div class="flex alc">
                  <el-input
                    v-model.number="form.queryCompanyDto.regCostMin"
                    placeholder="请输入最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number="form.queryCompanyDto.regCostMax"
                    placeholder="请输入最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="实缴资本">
                <div class="flex alc">
                  <el-input
                    v-model.number="form.queryCompanyDto.paidupCapitalMin"
                    placeholder="请输入最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number="form.queryCompanyDto.paidupCapitalMax"
                    placeholder="请输入最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="企业名称">
                <el-input
                  v-model="form.queryCompanyDto.name"
                  placeholder="请输入企业名称关键词"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="企业法人">
                <el-input
                  v-model="form.queryCompanyDto.legalPerson"
                  placeholder="请输入企业法人姓名"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="经营范围">
                <el-input
                  v-model="form.queryCompanyDto.businessScope"
                  placeholder="支持多关键词，如：公路 道路"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="注册地址">
                <el-input
                  v-model="form.queryCompanyDto.regAddress"
                  placeholder="请输入企业地址关键字，如：开发区"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="合作方式">
                <el-radio-group v-model="form.queryCompanyDto.joinIn">
                  <el-radio label="全部">全部</el-radio>
                  <el-radio label="加盟分公司"> 加盟分公司 </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="是否推荐">
                <el-radio-group v-model="form.queryCompanyDto.rmd">
                  <el-radio label="全部">全部</el-radio>
                  <el-radio label="是">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24" class="tac">
              <el-button
                @click="resetQueryCompanyDto"
                icon="el-icon-refresh-right"
              >
                重置
              </el-button>

              <!-- <el-button type="primary" @click="query" icon="el-icon-search">
                添加查询
              </el-button> -->
            </el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="资质分类" name="2">
          <div
            :class="[
              'flex',
              'alc',
              'jcsb',
              queryCertDto.certType.length > 0 ? 'mb20' : '',
            ]"
          >
            <el-button icon="el-icon-plus" @click="addQueryCertDtoCert">
              添加资质分类
            </el-button>

            <el-radio-group v-model="form.queryCertDto.subsection">
              <el-radio label="同时具备"></el-radio>
              <el-radio label="任意均可"></el-radio>
              <el-radio label="有且仅有"></el-radio>
            </el-radio-group>

            <el-checkbox v-model="form.queryCertDto.isExpire">
              包含资质到期
            </el-checkbox>
          </div>

          <el-row
            class="mb20"
            :gutter="40"
            v-for="(item, index) in queryCertDto.certType"
          >
            <el-col :span="22">
              <el-cascader
                v-model="item.value"
                :options="certTypeOptions"
                filterable
                :props="{
                  value: 'allDesc',
                  label: 'desc',
                  children: 'children',
                  emitPath: false,
                }"
                clearable
                placeholder="输入关键词搜索"
              ></el-cascader>
            </el-col>

            <el-col :span="2">
              <el-button
                @click="delQueryCertDtoCert(index)"
                type="danger"
                icon="el-icon-delete"
                circle
                plain
              ></el-button>
            </el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="业绩搜索" name="3">
          <el-button icon="el-icon-plus" @click="editQueryResultDto">
            编辑业绩搜索条件
          </el-button>

          <el-row
            :gutter="40"
            v-if="queryResultDto.isEdit"
            class="mt20 flex alc"
          >
            <el-col :span="22">
              <div class="project_keyword_text">
                <div>[业绩搜索]</div>
                <div>
                  {{
                    `项目地区：${
                      !form.queryResultDto.location
                        ? "不限地区"
                        : form.queryResultDto.location
                    }`
                  }}
                </div>

                <div
                  v-if="
                    form.queryResultDto.timeStart && form.queryResultDto.timeEnd
                  "
                >
                  {{
                    `中标时间：${form.queryResultDto.timeStart}~${form.queryResultDto.timeEnd}`
                  }}
                </div>

                <!-- <div v-if="form.queryResultDto.manager">
                  {{ `项目经理：${form.queryResultDto.manager}` }}
                </div>

                <div v-if="form.queryResultDto.technician">
                  {{ `技术负责人：${form.queryResultDto.technician}` }}
                </div>

                <div v-if="form.queryResultDto.secure">
                  {{ `专职安全员：${form.queryResultDto.secure}` }}
                </div> -->

                <div v-if="form.queryResultDto.winner">
                  {{ `中标单位：${form.queryResultDto.winner}` }}
                </div>

                <div v-if="form.queryResultDto.owner">
                  {{ `建设单位：${form.queryResultDto.owner}` }}
                </div>

                <div
                  v-if="
                    form.queryResultDto.amountMin &&
                    form.queryResultDto.amountMax
                  "
                >
                  {{
                    `金额：${form.queryResultDto.amountMin}~${form.queryResultDto.amountMax}`
                  }}
                </div>

                <div v-if="form.queryResultDto.sourceCn">
                  {{ `业绩来源：${form.queryResultDto.sourceCn}` }}
                </div>

                <div v-if="form.queryResultDto.nameInclude">
                  {{ `项目名称（包含）：${form.queryResultDto.nameInclude}` }}
                </div>

                <div v-if="form.queryResultDto.nameExclude">
                  {{ `项目名称（不包含）：${form.queryResultDto.nameExclude}` }}
                </div>
              </div>
            </el-col>

            <el-col :span="2">
              <el-button
                @click="deleteQueryResultDto"
                type="danger"
                icon="el-icon-delete"
                circle
                plain
              ></el-button>
            </el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="人员信息" name="4">
          <div
            :class="[
              'flex',
              'alc',
              'jcsb',
              queryPersonDto.personNoType.length > 0 ? 'mb20' : '',
            ]"
          >
            <el-button icon="el-icon-plus" @click="addQueryPersonDto">
              添加人员证书
            </el-button>
          </div>

          <el-row
            class="mb20"
            :gutter="40"
            v-for="(item, index) in queryPersonDto.personNoType"
          >
            <el-col :span="22">
              <el-cascader
                v-model="item.value"
                :options="personNoTypeOptions"
                filterable
                :props="{
                  value: 'allDesc',
                  label: 'desc',
                  children: 'children',
                  emitPath: false,
                }"
                clearable
                placeholder="输入关键词搜索"
              ></el-cascader>
            </el-col>

            <el-col :span="2">
              <el-button
                @click="delQueryPersonDto(index)"
                type="danger"
                icon="el-icon-delete"
                circle
                plain
              ></el-button>
            </el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="人员业绩" name="5">
          <div class="mb20">
            <el-button icon="el-icon-plus" @click="editQueryManagerDto">
              编辑项目经理业绩
            </el-button>

            <el-row
              v-if="queryManagerDto.isEdit"
              :gutter="40"
              class="mt20 flex alc"
            >
              <el-col :span="22" class="text">
                <div class="project_keyword_text">
                  <div>[项目经理业绩]</div>

                  <div v-if="form.queryManagerDto.name">
                    {{ `业绩名称：${form.queryManagerDto.name}` }}
                  </div>

                  <div
                    v-if="
                      form.queryManagerDto.timeStart &&
                      form.queryManagerDto.timeEnd
                    "
                  >
                    {{
                      `中标时间：${form.queryManagerDto.timeStart}~${form.queryManagerDto.timeEnd}`
                    }}
                  </div>

                  <div
                    v-if="
                      form.queryManagerDto.amountMin &&
                      form.queryManagerDto.amountMax
                    "
                  >
                    {{
                      `项目金额：${form.queryManagerDto.amountMin}~${form.queryManagerDto.amountMax}`
                    }}
                  </div>

                  <div v-if="form.queryManagerDto.personNoType">
                    {{ `证书：${form.queryManagerDto.personNoType}` }}
                  </div>
                </div>
              </el-col>

              <el-col :span="2">
                <el-button
                  @click="deleteQueryManagerDto"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  plain
                ></el-button>
              </el-col>
            </el-row>
          </div>

          <div>
            <el-button icon="el-icon-plus" @click="editQueryTechnicianDto">
              编辑技术负责人业绩
            </el-button>

            <el-row
              v-if="queryTechnicianDto.isEdit"
              :gutter="40"
              class="mt20 flex alc"
            >
              <el-col :span="22" class="text">
                <div class="project_keyword_text">
                  <div>[技术负责人经理业绩]</div>

                  <div v-if="form.queryTechnicianDto.name">
                    {{ `业绩名称：${form.queryTechnicianDto.name}` }}
                  </div>

                  <div
                    v-if="
                      form.queryTechnicianDto.timeStart &&
                      form.queryTechnicianDto.timeEnd
                    "
                  >
                    {{
                      `中标时间：${form.queryTechnicianDto.timeStart}~${form.queryTechnicianDto.timeEnd}`
                    }}
                  </div>

                  <div
                    v-if="
                      form.queryTechnicianDto.amountMin &&
                      form.queryTechnicianDto.amountMax
                    "
                  >
                    {{
                      `项目金额：${form.queryTechnicianDto.amountMin}~${form.queryTechnicianDto.amountMax}`
                    }}
                  </div>

                  <div v-if="form.queryTechnicianDto.personNoType">
                    {{ `证书：${form.queryTechnicianDto.personNoType}` }}
                  </div>
                </div>
              </el-col>

              <el-col :span="2">
                <el-button
                  @click="deleteQueryTechnicianDto"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  plain
                ></el-button>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>

        <el-collapse-item title="荣誉奖项" name="6">
          <div class="mb20">
            <el-button
              icon="el-icon-plus"
              @click="addQueryCompanyHonorDto(false)"
            >
              添加公司荣誉
            </el-button>

            <div v-if="form.queryCompanyHonorDto.length > 0">
              <div class="mt20 mb10">[公司荣誉]</div>

              <el-row
                :gutter="40"
                v-for="(item, index) in form.queryCompanyHonorDto"
                :key="'company' + index"
                class="mb20 flex alc"
              >
                <el-col :span="20">
                  <div class="project_keyword_text">
                    <div v-if="item.honorTypeCn">
                      {{
                        `[${item.honorTypeCn}${
                          item.grade ? "-" + item.grade : ""
                        }]`
                      }}
                    </div>
                    <div>
                      {{ `地区：${!item.place ? "不限地区" : item.place}` }}
                    </div>
                    <div v-if="item.timeMin && item.timeMax">
                      {{ `获奖时间：${item.timeMin}~${item.timeMax}` }}
                    </div>
                    <div v-if="item.company">
                      {{ `获奖企业：${item.company}` }}
                    </div>
                    <div v-if="item.announcedUnits">
                      {{ `颁发单位：${item.announcedUnits}` }}
                    </div>
                    <div v-if="item.nameIncludeList.length > 0">
                      {{
                        `荣誉名称（包含）：${item.nameIncludeList.join(",")}`
                      }}
                    </div>
                    <div v-if="item.nameExcludeList.length > 0">
                      {{
                        `荣誉名称（不包含）：${item.nameExcludeList.join(",")}`
                      }}
                    </div>
                  </div>
                </el-col>

                <el-col :span="4">
                  <el-button
                    @click="addQueryCompanyHonorDto(true, index)"
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    plain
                    title="编辑"
                  ></el-button>

                  <el-button
                    @click="delQueryCompanyHonorDto(index)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    plain
                    title="删除"
                  ></el-button>
                </el-col>
              </el-row>
            </div>
          </div>

          <div>
            <el-button
              icon="el-icon-plus"
              @click="addQueryPersonHonorDto(false)"
            >
              添加项目经理荣誉
            </el-button>

            <div v-if="form.queryPersonHonorDto.length > 0">
              <div class="mt20 mb10">[项目经理荣誉]</div>

              <el-row
                :gutter="40"
                v-for="(item, index) in form.queryPersonHonorDto"
                :key="'person' + index"
                class="mb20 flex alc"
              >
                <el-col :span="20">
                  <div class="project_keyword_text">
                    <div v-if="item.honorTypeCn">
                      {{
                        `[${item.honorTypeCn}${
                          item.grade ? "-" + item.grade : ""
                        }]`
                      }}
                    </div>
                    <div>
                      {{ `地区：${!item.place ? "不限地区" : item.place}` }}
                    </div>
                    <div v-if="item.timeMin && item.timeMax">
                      {{ `获奖时间：${item.timeMin}~${item.timeMax}` }}
                    </div>
                    <div v-if="item.company">
                      {{ `获奖企业：${item.company}` }}
                    </div>
                    <div v-if="item.announcedUnits">
                      {{ `颁发单位：${item.announcedUnits}` }}
                    </div>
                    <div v-if="item.nameIncludeList.length > 0">
                      {{
                        `荣誉名称（包含）：${item.nameIncludeList.join(",")}`
                      }}
                    </div>
                    <div v-if="item.nameExcludeList.length > 0">
                      {{
                        `荣誉名称（不包含）：${item.nameExcludeList.join(",")}`
                      }}
                    </div>
                  </div>
                </el-col>

                <el-col :span="4">
                  <el-button
                    @click="addQueryPersonHonorDto(true, index)"
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    plain
                    title="编辑"
                  ></el-button>

                  <el-button
                    @click="delQueryPersonHonorDto(index)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    plain
                    title="删除"
                  ></el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-collapse-item>

        <el-collapse-item title="信用评价" name="7">
          <el-button icon="el-icon-plus" @click="addQueryCreditDto(false)">
            添加信用评价
          </el-button>

          <div v-if="form.queryCreditDto.length > 0">
            <div class="mt20 mb10">[信用评价]</div>

            <el-row
              :gutter="40"
              v-for="(item, index) in form.queryCreditDto"
              :key="'cred' + index"
              class="mb20 flex alc"
            >
              <el-col :span="20">
                <div class="project_keyword_text">
                  <div>
                    {{ `地区：${!item.place ? "不限地区" : item.place}` }}
                  </div>
                  <div v-if="item.sourceCn">
                    {{ `诚信来源：${item.sourceCn}` }}
                  </div>
                  <div v-if="item.company">
                    {{ `企业名称：${item.company}` }}
                  </div>
                  <div
                    v-if="
                      item.nameIncludeList.length > 0 && item.nameIncludeList[0]
                    "
                  >
                    {{ `诚信名称：${item.nameIncludeList[0]}` }}
                  </div>
                  <div v-if="item.content">
                    {{ `诚信内容：${item.content}` }}
                  </div>
                  <div v-if="item.timeMin && item.timeMax">
                    {{ `发布时间：${item.timeMin}~${item.timeMax}` }}
                  </div>
                </div>
              </el-col>

              <el-col :span="4">
                <el-button
                  @click="addQueryCreditDto(true, index)"
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  plain
                  title="编辑"
                ></el-button>

                <el-button
                  @click="delQueryCreditDto(index)"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  plain
                  title="删除"
                ></el-button>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>

    <!-- 业绩条件查询项 -->
    <customDialog
      v-model="queryResultDto.show"
      title="编辑业绩搜索条件"
      width="1120px"
    >
      <performance
        :formData="form.queryResultDto"
        :nameList="queryResultDto.projectNameList"
        :timeData="queryResultDto.time"
        :locationData="queryResultDto.location"
        @cancel="handlePerformanceCancel"
        @confirm="handlePerformanceConfirm"
      />
    </customDialog>

    <!-- 项目经理业绩查询项 -->
    <customDialog
      v-model="queryManagerDto.show"
      title="编辑项目经理业绩"
      width="1120px"
    >
      <personnelPerformance
        :formData="form.queryManagerDto"
        :typeData="queryManagerDto.personNoType"
        :timeData="queryManagerDto.time"
        @cancel="handleQueryManagerDtoCancel"
        @confirm="handleQueryManagerDtoConfirm"
      />
    </customDialog>

    <!-- 技术负责人业绩查询项 -->
    <customDialog
      v-model="queryTechnicianDto.show"
      title="编辑技术负责人业绩"
      width="1120px"
    >
      <personnelPerformance
        :formData="form.queryTechnicianDto"
        :typeData="queryTechnicianDto.personNoType"
        :timeData="queryTechnicianDto.time"
        @cancel="handleQueryTechnicianDtoCancel"
        @confirm="handleQueryTechnicianDtoConfirm"
      />
    </customDialog>

    <!-- 公司荣誉查询项 -->
    <customDialog
      v-model="queryCompanyHonorDto.show"
      title="编辑公司荣誉查询项"
      width="1120px"
    >
      <honor
        :formData="queryCompanyHonorDto.form"
        :nameList="queryCompanyHonorDto.honorNameList"
        :timeData="queryCompanyHonorDto.time"
        :placeData="queryCompanyHonorDto.place"
        @cancel="handleQueryCompanyHonorDtoCancel"
        @confirm="handleQueryCompanyHonorDtoConfirm"
      />
    </customDialog>

    <!-- 项目经理荣誉查询项 -->
    <customDialog
      v-model="queryPersonHonorDto.show"
      title="编辑项目经理荣誉查询项"
      width="1120px"
    >
      <honor
        :formData="queryPersonHonorDto.form"
        :nameList="queryPersonHonorDto.honorNameList"
        :timeData="queryPersonHonorDto.time"
        :placeData="queryPersonHonorDto.place"
        @cancel="handleQueryPersonHonorDtoCancel"
        @confirm="handleQueryPersonHonorDtoConfirm"
      />
    </customDialog>

    <!-- 信用评价查询项 -->
    <customDialog
      v-model="queryCreditDto.show"
      title="编辑信用评价查询项"
      width="1120px"
    >
      <integrity
        :formData="queryCreditDto.form"
        :timeData="queryCreditDto.time"
        :placeData="queryCreditDto.place"
        @cancel="handleQueryCreditDtoCancel"
        @confirm="handleQueryCreditDtoConfirm"
      />
    </customDialog>
  </div>
</template>

<script>
/* 组合查询 */
// 引入ajax函数
import { searchCompany } from "@/api/itemizedQuery";
import { sourceTree } from "@/api/common";
// 引入区域json
import area from "@/utils/area";
// 引入组件
import performance from "./components/performance.vue";
import personnelPerformance from "./components/personnelPerformance.vue";
import honor from "./components/honor.vue";
import integrity from "./components/integrity.vue";
// 引入lodash插件
import _ from "lodash";

export default {
  name: "combinedQuery",

  components: { performance, personnelPerformance, honor, integrity },

  data() {
    return {
      activeNames: ["1", "2", "3", "4", "5", "6", "7"], // 折叠面板激活项

      form: {
        // 企业信息
        queryCompanyDto: {
          location: [], // 企业地区,
          joinIn: "全部", // 合作方式
          rmd: "全部", // 合作方式
          name: "", // 企业名称
          regCostMin: "", // 注册资金最小值
          regCostMax: "", // 注册资金最大值
          paidupCapitalMin: "", // 实缴资本最小值
          paidupCapitalMax: "", // 实缴资本最大值
          startDateStart: "", // 开始时间
          startDateEnd: "", // 结束时间
          legalPerson: "", // 企业法人
          // tag: [], // 企业标签
          businessScope: "", // 经营范围
          regAddress: "", // 注册地址
        },
        // 资质分类
        queryCertDto: {
          certType: "", // 资质分类
          isExpire: true, // 是否到期
          subsection: "同时具备", // 筛选条件
        },
        // 业绩搜索
        queryResultDto: {
          location: [], // 企业地区,
          source: "", // 来源平台
          resultType: null, // 业绩类型-预留字段
          projectType: null, // 项目类型-预留字段
          nameInclude: "", // 包含的项目名称
          nameExclude: "", // 不包含的项目名称
          manager: "", // 项目经理
          technician: "", // 技术负责人
          secure: "", // 专职安全员
          timeStart: "", // 中标时间-开始
          timeEnd: "", // 中标时间-结束
          amountMin: "", // 金额-最小
          amountMax: "", // 金额-最大
          winner: "", // 中标单位
          owner: "", // 建设单位
        },
        // 人员信息
        queryPersonDto: {
          personNoType: "", // 人员证书
        },
        // 项目经理业绩
        queryManagerDto: {
          personNoType: "", // 人员证书
          timeStart: "", // 中标时间-开始
          timeEnd: "", // 中标时间-结束
          amountMin: "", // 金额-最小
          amountMax: "", // 金额-最大
          name: "", // 业绩名称
        },
        // 技术负责人业绩
        queryTechnicianDto: {
          personNoType: "", // 人员证书
          timeStart: "", // 中标时间-开始
          timeEnd: "", // 中标时间-结束
          amountMin: "", // 金额-最小
          amountMax: "", // 金额-最大
          name: "", // 业绩名称
        },
        queryCompanyHonorDto: [], // 公司荣誉
        queryPersonHonorDto: [], // 项目经理荣誉
        queryCreditDto: [], // 信用评价
        pageSize: 20,
        pageNum: 1,
      },

      areaOptions: [], // 地区数据
      queryCompanyDtoTime: [], // 企业信息-成立时间
      certTypeOptions: [], // 资质分类选项数据
      personNoTypeOptions: [], // 人员证书选项数据
      // 资质分类
      queryCertDto: {
        certType: [],
      },
      // 业绩搜索
      queryResultDto: {
        isEdit: false,
        show: false,
        projectNameList: [
          {
            radio: "",
            keyword: "",
          },
        ],
        location: [],
        time: [],
      },
      // 人员信息
      queryPersonDto: {
        personNoType: [], // 人员证书
      },
      // 项目经理业绩
      queryManagerDto: {
        isEdit: false,
        show: false,
        personNoType: [{ value: "" }],
        time: [],
      },
      // 技术负责人业绩
      queryTechnicianDto: {
        isEdit: false,
        show: false,
        personNoType: [{ value: "" }],
        time: [],
      },
      // 公司荣誉
      queryCompanyHonorDto: {
        show: false, // 编辑搜索项模态框显示状态
        editFlag: false, // 编辑状态-true表示为编辑
        editIndex: 0, // 当前编辑项的index
        // 荣誉姓名表
        honorNameList: [
          {
            radio: "",
            keyword: "",
          },
        ],
        time: [], // 时间
        place: [], // 地区
        // 搜索表单项
        form: {
          place: [], // 地区
          timeMin: "", // 获奖时间-最小值
          timeMax: "", // 获奖时间-最大值
          company: "", // 获奖企业
          announcedUnits: "", // 颁发单位
          honorType: "", // 数据字典：honorType
          honorTypeCn: "", // 数据字典：honorType
          grade: "", // 数据字典：honorGrade
          nameIncludeList: [], // 荣誉名称-包含
          nameExcludeList: [], // 荣誉名称-不包含
        },
        originalData: [], // 原本的数据-由模态框传回来的data,在编辑数据时需要使用
      },
      // 项目经理荣誉
      queryPersonHonorDto: {
        show: false, // 编辑搜索项模态框显示状态
        editFlag: false, // 编辑状态-true表示为编辑
        editIndex: 0, // 当前编辑项的index
        // 荣誉姓名表
        honorNameList: [
          {
            radio: "",
            keyword: "",
          },
        ],
        time: [], // 时间
        place: [], // 地区
        // 搜索表单项
        form: {
          place: [], // 地区
          timeMin: "", // 获奖时间-最小值
          timeMax: "", // 获奖时间-最大值
          company: "", // 获奖企业
          announcedUnits: "", // 颁发单位
          honorType: "", // 数据字典：honorType
          honorTypeCn: "", // 数据字典：honorType
          grade: "", // 数据字典：honorGrade
          nameIncludeList: [], // 荣誉名称-包含
          nameExcludeList: [], // 荣誉名称-不包含
        },
        originalData: [], // 原本的数据-由模态框传回来的data,在编辑数据时需要使用
      },
      // 信用评价
      queryCreditDto: {
        show: false, // 编辑搜索项模态框显示状态
        editFlag: false, // 编辑状态-true表示为编辑
        editIndex: 0, // 当前编辑项的index
        time: [], // 时间
        place: [], // 地区
        // 搜索表单项
        form: {
          place: [], // 地区
          source: "", // 诚信来源
          sourceCn: "", // 诚信来源-中文
          company: "", // 企业名称
          nameIncludeList: [""], // 诚信名称关键词（就用数组传吧，懒得改了）
          content: "", // 诚信内容
          timeMin: "", // 发布时间-开始
          timeMax: "", // 发布时间-结束
        },
        originalData: [], // 原本的数据-由模态框传回来的data,在编辑数据时需要使用
      },
      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },

  methods: {
    /* 获取数据 */
    // 获取列表数据
    getData() {},
    // 获取下拉数据
    async getOptions() {
      // 资质分类
      const certTypeOptions = await sourceTree({ source: "certType" });
      this.certTypeOptions = certTypeOptions;
      // 人员证书
      const personNoTypeOptions = await sourceTree({
        source: "personnelNoType",
      });
      this.personNoTypeOptions = personNoTypeOptions;
    },

    /* 操作 */
    /* 企业信息 */
    // 重置企业信息
    resetQueryCompanyDto() {
      this.form.queryCompanyDto = {
        location: [], // 企业地区,
        joinIn: "全部", // 合作方式
        rmd: "全部", // 合作方式
        name: "", // 企业名称
        regCostMin: "", // 注册资金最小值
        regCostMax: "", // 注册资金最大值
        paidupCapitalMin: "", // 实缴资本最小值
        paidupCapitalMax: "", // 实缴资本最大值
        startDateStart: "", // 开始时间
        startDateEnd: "", // 结束时间
        legalPerson: "", // 企业法人
        // tag: [], // 企业标签
        businessScope: "", // 经营范围
        regAddress: "", // 注册地址
      };
    },
    /* 资质分类 */
    // 添加资质分类搜索项
    addQueryCertDtoCert() {
      if (this.queryCertDto.certType.length < 5) {
        this.queryCertDto.certType.push({
          value: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除资质分类
    delQueryCertDtoCert(index) {
      this.queryCertDto.certType.splice(index, 1);
    },
    /* 业绩条件 */
    // 编辑业绩条件
    editQueryResultDto() {
      this.queryResultDto.show = true;
    },
    // 业绩条件取消事件
    handlePerformanceCancel() {
      this.queryResultDto.show = false;
    },
    // 业绩条件确认事件
    handlePerformanceConfirm(val) {
      this.form.queryResultDto = _.cloneDeep(val.params);
      this.queryResultDto.projectNameList = _.cloneDeep(val.projectNameList);
      this.queryResultDto.location = _.cloneDeep(val.location);
      this.queryResultDto.time = _.cloneDeep(val.time);
      this.queryResultDto.show = false;

      let queryResultDtoKeys = Object.keys(val.params);
      if (queryResultDtoKeys.length > 0) {
        if (!val.params.location) {
          val.params.location = "不限";
        }
        // 判断传递回来的参数每一项是否有值，有值则表示已经添加过数据
        let temp = queryResultDtoKeys.filter((v) => val.params[v]);

        if (temp.length > 0) {
          this.queryResultDto.isEdit = true;
        } else {
          this.queryResultDto.isEdit = false;
        }
      }
    },
    // 删除业绩搜索条件
    deleteQueryResultDto() {
      this.form.queryResultDto = {
        location: [], // 企业地区,
        source: "", // 来源平台
        resultType: null, // 业绩类型-预留字段
        projectType: null, // 项目类型-预留字段
        nameInclude: "", // 包含的项目名称
        nameExclude: "", // 不包含的项目名称
        manager: "", // 项目经理
        technician: "", // 技术负责人
        secure: "", // 专职安全员
        timeStart: "", // 中标时间-开始
        timeEnd: "", // 中标时间-结束
        amountMin: "", // 金额-最小
        amountMax: "", // 金额-最大
        winner: "", // 中标单位
        owner: "", // 建设单位
      };
      this.queryResultDto.projectNameList = [
        {
          radio: "",
          keyword: "",
        },
      ];
      this.queryResultDto.location = [];
      this.queryResultDto.time = [];
      this.queryResultDto.isEdit = false;
    },
    /* 人员证书 */
    // 添加人员证书
    addQueryPersonDto() {
      if (this.queryPersonDto.personNoType.length < 5) {
        this.queryPersonDto.personNoType.push({
          value: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除人员证书
    delQueryPersonDto(index) {
      this.queryPersonDto.personNoType.splice(index, 1);
    },
    /* 项目经理业绩 */
    // 编辑项目经理业绩
    editQueryManagerDto() {
      this.queryManagerDto.show = true;
    },
    // 项目经理业绩确认事件
    handleQueryManagerDtoConfirm(val) {
      this.form.queryManagerDto = _.cloneDeep(val.params);
      this.queryManagerDto.personNoType = _.cloneDeep(val.personNoType);
      this.queryManagerDto.time = _.cloneDeep(val.time);

      let queryManagerDtoKeys = Object.keys(val.params);
      if (queryManagerDtoKeys.length > 0) {
        // 判断传递回来的参数每一项是否有值，有值则表示已经添加过数据
        let temp = queryManagerDtoKeys.filter((v) => val.params[v]);

        if (temp.length > 0) {
          this.queryManagerDto.isEdit = true;
        } else {
          this.queryManagerDto.isEdit = false;
        }
      }
      this.queryManagerDto.show = false;
    },
    // 项目经理业绩取消事件
    handleQueryManagerDtoCancel() {
      this.queryManagerDto.show = false;
    },
    // 删除项目经理业绩
    deleteQueryManagerDto() {
      this.form.queryManagerDto = {
        personNoType: "", // 人员证书
        timeStart: "", // 中标时间-开始
        timeEnd: "", // 中标时间-结束
        amountMin: "", // 金额-最小
        amountMax: "", // 金额-最大
        name: "", // 业绩名称
      };
      this.queryManagerDto.personNoType = [{ value: "" }];
      this.queryManagerDto.time = [];
      this.queryManagerDto.isEdit = false;
    },
    /* 技术负责人业绩 */
    // 编辑技术负责人业绩
    editQueryTechnicianDto() {
      this.queryTechnicianDto.show = true;
    },
    // 技术负责人业绩确认事件
    handleQueryTechnicianDtoConfirm(val) {
      this.form.queryTechnicianDto = _.cloneDeep(val.params);
      this.queryTechnicianDto.personNoType = _.cloneDeep(val.personNoType);
      this.queryTechnicianDto.time = _.cloneDeep(val.time);

      let queryTechnicianDtoKeys = Object.keys(val.params);
      if (queryTechnicianDtoKeys.length > 0) {
        // 判断传递回来的参数每一项是否有值，有值则表示已经添加过数据
        let temp = queryTechnicianDtoKeys.filter((v) => val.params[v]);

        if (temp.length > 0) {
          this.queryTechnicianDto.isEdit = true;
        } else {
          this.queryTechnicianDto.isEdit = false;
        }
      }
      this.queryTechnicianDto.show = false;

      // this.queryTechnicianDto.show = false;
    },
    // 技术负责人业绩取消事件
    handleQueryTechnicianDtoCancel() {
      this.queryTechnicianDto.show = false;
    },
    // 删除技术负责人业绩
    deleteQueryTechnicianDto() {
      this.form.queryTechnicianDto = {
        personNoType: "", // 人员证书
        timeStart: "", // 中标时间-开始
        timeEnd: "", // 中标时间-结束
        amountMin: "", // 金额-最小
        amountMax: "", // 金额-最大
        name: "", // 业绩名称
      };
      this.queryTechnicianDto.personNoType = [{ value: "" }];
      this.queryTechnicianDto.time = [];
      this.queryTechnicianDto.isEdit = false;
    },
    /* 公司荣誉 */
    // 添加公司荣誉
    addQueryCompanyHonorDto(flag, index) {
      let data = this.queryCompanyHonorDto;

      if (flag) {
        // 编辑-将原本的数据赋值给传递给父组件的数据
        data.editFlag = true;
        data.editIndex = index;
        data.form = _.cloneDeep(data.originalData[index].params);
        data.honorNameList = _.cloneDeep(
          data.originalData[index].honorNameList
        );
        data.time = _.cloneDeep(data.originalData[index].time);
        data.place = _.cloneDeep(data.originalData[index].place);
      } else {
        // 新增-重置传递给父组件的数据
        data.editFlag = false;
        data.editIndex = null;
        data.form = {
          place: [], // 地区
          timeMin: "", // 获奖时间-最小值
          timeMax: "", // 获奖时间-最大值
          company: "", // 获奖企业
          announcedUnits: "", // 颁发单位
          honorType: "", // 数据字典：honorType
          honorTypeCn: "", // 数据字典：honorType
          grade: "", // 数据字典：honorGrade
          nameIncludeList: [], // 荣誉名称-包含
          nameExcludeList: [], // 荣誉名称-不包含
        };
        data.time = [];
        data.place = [];
        data.honorNameList = [{ radio: "", keyword: "" }];
      }
      // 当状态为编辑或数据长度小于5条时 展示模态框否则弹出提示
      if (flag || this.form.queryCompanyHonorDto.length < 5) {
        data.show = true;
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 添加公司荣誉确认事件
    handleQueryCompanyHonorDtoConfirm(val) {
      let data = this.queryCompanyHonorDto;
      let isEdit = false;
      let keys = Object.keys(val.params);
      if (keys.length > 0) {
        // 判断传递回来的参数每一项是否有值，有值则表示已经添加过数据
        let temp = keys.filter((v) => val.params[v].length > 0);

        if (temp.length > 0) {
          isEdit = true;
        } else {
          isEdit = false;
        }
      }

      if (data.editFlag) {
        // 编辑
        let index = data.editIndex;
        if (isEdit) {
          this.form.queryCompanyHonorDto[index] = val.params;
          data.originalData[index] = val;
        } else {
          this.form.queryCompanyHonorDto.splice(index, 1);
          data.originalData.splice(index, 1);
        }
      } else {
        // 新增
        if (isEdit) {
          this.form.queryCompanyHonorDto.push(val.params);
          data.originalData.push(val);
        }
      }
      data.place = val.place;
      data.time = val.time;
      data.honorNameList = val.honorNameList;
      data.show = false;
    },
    // 添加公司荣誉取消事件
    handleQueryCompanyHonorDtoCancel() {
      this.queryCompanyHonorDto.show = false;
    },
    // 删除公司荣誉
    delQueryCompanyHonorDto(index) {
      this.form.queryCompanyHonorDto.splice(index, 1);
    },
    /* 项目经理荣誉 */
    // 添加项目经理荣誉
    addQueryPersonHonorDto(flag, index) {
      let data = this.queryPersonHonorDto;

      if (flag) {
        // 编辑-将原本的数据赋值给传递给父组件的数据
        data.editFlag = true;
        data.editIndex = index;
        data.form = _.cloneDeep(data.originalData[index].params);
        data.honorNameList = _.cloneDeep(
          data.originalData[index].honorNameList
        );
        data.time = _.cloneDeep(data.originalData[index].time);
        data.place = _.cloneDeep(data.originalData[index].place);
      } else {
        // 新增-重置传递给父组件的数据
        data.editFlag = false;
        data.editIndex = null;
        data.form = {
          place: [], // 地区
          timeMin: "", // 获奖时间-最小值
          timeMax: "", // 获奖时间-最大值
          company: "", // 获奖企业
          announcedUnits: "", // 颁发单位
          honorType: "", // 数据字典：honorType
          honorTypeCn: "", // 数据字典：honorType
          grade: "", // 数据字典：honorGrade
          nameIncludeList: [], // 荣誉名称-包含
          nameExcludeList: [], // 荣誉名称-不包含
        };
        data.time = [];
        data.place = [];
        data.honorNameList = [{ radio: "", keyword: "" }];
      }
      // 当状态为编辑或数据长度小于5条时 展示模态框否则弹出提示
      if (flag || this.form.queryPersonHonorDto.length < 5) {
        data.show = true;
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 添加项目经理荣誉确认事件
    handleQueryPersonHonorDtoConfirm(val) {
      let data = this.queryPersonHonorDto;
      let isEdit = false;
      let keys = Object.keys(val.params);
      if (keys.length > 0) {
        // 判断传递回来的参数每一项是否有值，有值则表示已经添加过数据
        let temp = keys.filter((v) => val.params[v].length > 0);

        if (temp.length > 0) {
          isEdit = true;
        } else {
          isEdit = false;
        }
      }

      if (data.editFlag) {
        // 编辑
        let index = data.editIndex;
        if (isEdit) {
          this.form.queryPersonHonorDto[index] = val.params;
          data.originalData[index] = val;
        } else {
          this.form.queryPersonHonorDto.splice(index, 1);
          data.originalData.splice(index, 1);
        }
      } else {
        // 新增
        if (isEdit) {
          this.form.queryPersonHonorDto.push(val.params);
          data.originalData.push(val);
        }
      }
      data.place = val.place;
      data.time = val.time;
      data.honorNameList = val.honorNameList;
      data.show = false;
    },
    // 添加项目经理荣誉取消事件
    handleQueryPersonHonorDtoCancel() {
      this.queryPersonHonorDto.show = false;
    },
    // 删除项目经理荣誉
    delQueryPersonHonorDto(index) {
      this.form.queryPersonHonorDto.splice(index, 1);
    },
    /* 信用评价 */
    // 添加信用评价
    addQueryCreditDto(flag, index) {
      let data = this.queryCreditDto;

      if (flag) {
        // 编辑-将原本的数据赋值给传递给父组件的数据
        data.editFlag = true;
        data.editIndex = index;
        data.form = _.cloneDeep(data.originalData[index].params);
        data.time = _.cloneDeep(data.originalData[index].time);
        data.place = _.cloneDeep(data.originalData[index].place);
      } else {
        // 新增-重置传递给父组件的数据
        data.editFlag = false;
        data.editIndex = null;
        data.form = {
          place: [], // 地区
          source: "", // 诚信来源
          sourceCn: "", // 诚信来源-中文
          company: "", // 企业名称
          nameIncludeList: [""], // 诚信名称关键词（就用数组传吧，懒得改了）
          content: "", // 诚信内容
          timeMin: "", // 发布时间-开始
          timeMax: "", // 发布时间-结束
        };
        data.time = [];
        data.place = [];
      }
      // 当状态为编辑或数据长度小于5条时 展示模态框否则弹出提示
      if (flag || this.form.queryCreditDto.length < 5) {
        data.show = true;
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 添加信用评价确认事件
    handleQueryCreditDtoConfirm(val) {
      let data = this.queryCreditDto;
      let isEdit = false;
      /* 判断传递回来的参数（params）是否有值 */
      let keys = Object.keys(val.params);
      if (keys.length > 0) {
        // 判断传递回来的参数每一项是否有值，有值则表示已经添加过数据
        let temp = keys.filter((v) => val.params[v].length > 0);

        if (temp.length > 0) {
          isEdit = true;
        } else {
          isEdit = false;
        }
      }

      if (data.editFlag) {
        // 编辑
        let index = data.editIndex;
        if (isEdit) {
          this.form.queryCreditDto[index] = val.params;
          data.originalData[index] = val;
        } else {
          this.form.queryCreditDto.splice(index, 1);
          data.originalData.splice(index, 1);
        }
      } else {
        // 新增
        if (isEdit) {
          this.form.queryCreditDto.push(val.params);
          data.originalData.push(val);
        }
      }
      data.place = val.place;
      data.time = val.time;
      data.show = false;
    },
    // 添加信用评价取消事件
    handleQueryCreditDtoCancel() {
      this.queryCreditDto.show = false;
    },
    // 删除信用评价
    delQueryCreditDto(index) {
      this.form.queryCreditDto.splice(index, 1);
    },
    // 初始化
    __init__() {
      this.areaOptions = area;
      this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例创建完成后
  async created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.combined_query {
  /deep/.el-collapse-item {
    padding: 20px 20px 0px;
    background-color: #fff;
    margin-bottom: 20px;
  }

  /deep/.el-collapse-item__header {
    padding-left: 10px;
    border-bottom: 1px solid #ebeef5;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }

  /deep/.el-collapse-item__content {
    padding-top: 25px;
  }

  /deep/.el-collapse-item__wrap {
    border-bottom: none;
  }

  /deep/.el-cascader,
  /deep/.el-date-editor {
    width: 100%;
  }
}
</style>
