<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="诚信所在地">
            <el-cascader
              v-model="form.place"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
              style="width: 100%"
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="发布时间">
            <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="企业名称">
            <el-input
              v-model.trim="form.company"
              placeholder="请输入企业名称关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="诚信名称">
            <el-input
              v-model.trim="form.nameIncludeList[0]"
              placeholder="请输入诚信名称关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="诚信来源">
            <el-select
              style="width: 100%"
              v-model="form.source"
              placeholder="请选择诚信来源"
              @change="handleSourceChange"
              clearable
            >
              <el-option
                v-for="item in sourceOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="诚信内容">
            <el-input
              v-model.trim="form.content"
              placeholder="请输入诚信内容关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="tar">
        <el-button @click="cancel"> 取消 </el-button>

        <el-button @click="reset"> 重置 </el-button>

        <el-button type="primary" @click="submit"> 确定 </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
/* 组合查询-添加信用评价查询项 */
/* 
  formData: 表单数据-从父组件传递过来的参数
  placeData: 地区数据
  timeData: 时间数据
*/
// 引入ajax函数
import { getDict } from "@/api/common";
import _ from "lodash"; // 引入lodash插件
import area from "@/utils/area"; // 引入区域json

export default {
  props: ["formData", "placeData", "timeData"],
  // 数据
  data() {
    return {
      form: {
        place: [], // 地区
        source: "", // 诚信来源
        sourceCn: "", // 诚信来源-中文
        company: "", // 企业名称
        nameIncludeList: [""], // 诚信名称关键词（就用数组传吧，懒得改了）
        content: "", // 诚信内容
        timeMin: "", // 发布时间-开始
        timeMax: "", // 发布时间-结束
      },

      time: [], // 时间
      sourceOptions: [], // 诚信来源下拉数据
      areaOptions: [], // 地区数据

      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取树形数据
    async getOptions() {
      // 诚信来源
      const sourceOptions = await getDict({ source: "integritySource" });
      this.sourceOptions = sourceOptions;
    },

    /* 操作 */
    // 来源change事件
    handleSourceChange(val) {
      let sourceCn = "";
      if (val) {
        sourceCn = this.sourceOptions
          .filter((v) => v.value == val)
          .map((v) => v.name)[0];
      }
      this.form.sourceCn = sourceCn;
    },
    // 取消
    cancel() {
      this.$emit("cancel");
    },
    // 重置
    reset() {
      this.form = {
        place: [], // 地区
        source: "", // 诚信来源
        sourceCn: "", // 诚信来源-中文
        company: "", // 企业名称
        nameIncludeList: [""], // 诚信名称关键词（就用数组传吧，懒得改了）
        content: "", // 诚信内容
        timeMin: "", // 发布时间-开始
        timeMax: "", // 发布时间-结束
      };
      this.time = [];
    },
    // 查询
    submit() {
      const place = this.form.place.filter((v) => v != "").join("-");
      let timeMin = "";
      let timeMax = "";
      if (this.time.length > 0) {
        timeMin = this.time[0];
        timeMax = this.time[1];
      }

      const params = {
        ...this.form,
        place,
        timeMin,
        timeMax,
      };

      let data = {
        params,
        time: this.time,
        place: this.form.place,
      };

      this.$emit("confirm", data);
    },
    // 初始化
    __init__() {
      this.areaOptions = area;
      this.getOptions();
    },
  },

  // 数据监听
  watch: {
    formData: {
      handler(val) {
        if (val) {
          this.form = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },

    placeData: {
      handler(val) {
        if (val) {
          this.form.place = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },

    timeData: {
      handler(val) {
        if (val) {
          this.time = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 生命周期-实例创建完成后
  async created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .list {
    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px solid #efefef;
      cursor: pointer;

      .index {
        margin-right: 20px;
        color: #999999;
        font-size: 22px;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 30px;

        .tag {
          font-size: 16px;
          padding: 2px 8px 4px;
          border-radius: 4px;
          line-height: 22px;
          color: #1d89e3;
          background-color: #d7edfb;
        }
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          margin-right: 80px;
          flex: 1;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
        .name {
          color: #1d89e3;
        }
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
