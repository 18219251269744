<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="项目地区">
            <el-cascader
              v-model="form.location"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标时间">
            <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="中标单位">
            <el-input
              v-model.trim="form.winner"
              placeholder="请输入中标单位关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="建设单位">
            <el-input
              v-model.trim="form.owner"
              placeholder="请输入建设单位关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="业绩来源/类型">
            <el-select
              style="width: 100%"
              v-model="form.source"
              placeholder="请选择业绩所在平台"
              clearable
              @change="handleSourceChange"
            >
              <el-option
                v-for="item in sourceOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="金额">
            <div class="flex alc">
              <el-input
                v-model.number.trim="form.amountMin"
                placeholder="请输入最小值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>

              <span class="ml10 mr10">至</span>

              <el-input
                v-model.number.trim="form.amountMax"
                placeholder="请输入最大值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="项目名称" style="margin-bottom: 0px">
        <el-row
          class="mb22 flex alc"
          v-for="(item, index) in projectNameList"
          :key="'project' + index"
        >
          <el-col class="mr20 flex4">
            <el-input
              placeholder="多关键词用空格隔开，如：园林 绿化"
              v-model.trim="item.keyword"
              class="input-with-select"
              clearable
            >
              <el-select
                style="width: 130px"
                v-model="item.radio"
                slot="prepend"
                placeholder="请选择"
                clearable
              >
                <el-option label="包含" value="包含"></el-option>
                <el-option label="不包含" value="不包含"></el-option>
              </el-select>
            </el-input>
          </el-col>

          <el-col class="flex1">
            <el-button
              v-if="index == 0"
              type="primary"
              icon="el-icon-plus"
              plain
              @click="addName"
            >
              增加
            </el-button>

            <el-button
              v-else
              @click="delName(index)"
              type="danger"
              icon="el-icon-delete"
              circle
              plain
            ></el-button>
          </el-col>
        </el-row>
      </el-form-item>

      <div class="tar">
        <el-button @click="cancel"> 取消 </el-button>

        <el-button @click="reset"> 重置 </el-button>

        <el-button type="primary" @click="submit"> 确定 </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
/* 组合查询-添加业绩条件 */
/*
  formData: 表单数据-从父组件传递过来的参数
  nameList：项目名称列表
  locationData：地区数据
  timeData：时间数据
*/
import area from "@/utils/area"; // 引入区域json
import { getDict } from "@/api/common"; // 引入ajax函数
import _ from "lodash"; // 引入lodash插件

export default {
  props: ["formData", "nameList", "locationData", "timeData"],
  // 数据
  data() {
    return {
      form: {
        location: [], // 企业地区,
        source: "", // 来源平台
        sourceCn: "", // 来源平台-中文
        resultType: null, // 业绩类型-预留字段
        projectType: null, // 项目类型-预留字段
        nameInclude: "", // 包含的项目名称
        nameExclude: "", // 不包含的项目名称
        manager: "", // 项目经理
        technician: "", // 技术负责人
        secure: "", // 专职安全员
        timeStart: "", // 中标时间-开始
        timeEnd: "", // 中标时间-结束
        amountMin: "", // 金额-最小
        amountMax: "", // 金额-最大
        winner: "", // 中标单位
        owner: "", // 建设单位
      },

      time: [], // 时间
      sourceOptions: [], // 业绩来源下拉数据
      // 项目名称
      projectNameList: [
        {
          radio: "",
          keyword: "",
        },
      ],

      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取下拉选项数据
    async getOptions() {
      // 业绩来源
      const sourceList = await getDict({ source: "sourceList" });
      this.sourceOptions = sourceList;
    },

    /* 操作 */
    // 业绩来源change事件
    handleSourceChange(val) {
      let sourceCn = "";
      if (val) {
        sourceCn = this.sourceOptions
          .filter((v) => v.value == val)
          .map((v) => v.name)[0];
      }
      this.form.sourceCn = sourceCn;
    },
    // 添加项目名称
    addName() {
      if (this.projectNameList.length < 5) {
        this.projectNameList.push({
          radio: "",
          keyword: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除项目名称
    delName(index) {
      this.projectNameList.splice(index, 1);
    },
    // 重置
    reset() {
      this.form = {
        location: [], // 企业地区,
        source: "", // 来源平台
        sourceCn: "", // 来源平台-中文
        resultType: null, // 业绩类型-预留字段
        projectType: null, // 项目类型-预留字段
        nameInclude: "", // 包含的项目名称
        nameExclude: "", // 不包含的项目名称
        manager: "", // 项目经理
        technician: "", // 技术负责人
        secure: "", // 专职安全员
        timeStart: "", // 中标时间-开始
        timeEnd: "", // 中标时间-结束
        amountMin: "", // 金额-最小
        amountMax: "", // 金额-最大
        winner: "", // 中标单位
        owner: "", // 建设单位
      };
      this.time = [];
      this.projectNameList = [
        {
          radio: "",
          keyword: "",
        },
      ];
    },
    // 取消
    cancel() {
      this.$emit("cancel");
    },
    // 确定
    submit() {
      const location = this.form.location.filter((v) => v != "").join("-");
      let timeStart = "";
      let timeEnd = "";
      if (this.time.length > 0) {
        timeStart = this.time[0];
        timeEnd = this.time[1];
      }
      const nameInclude = this.projectNameList
        .filter((v) => v.radio == "包含" && v.keyword)
        .map((v) => v.keyword)
        .join(" ");
      const nameExclude = this.projectNameList
        .filter((v) => v.radio == "不包含" && v.keyword)
        .map((v) => v.keyword)
        .join(" ");

      console.log("nameInclude>>>>", nameInclude);
      const params = {
        ...this.form,
        location,
        timeStart,
        timeEnd,
        nameInclude,
        nameExclude,
      };

      const data = {
        params,
        location: this.form.location,
        time: this.time,
        projectNameList: this.projectNameList,
      };
      console.log("params>>>>", data);
      this.$emit("confirm", data);
    },
    // 初始化
    __init__() {
      this.areaOptions = area;
      this.getOptions();
    },
  },

  watch: {
    formData: {
      handler(val) {
        if (val) {
          this.form = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },

    nameList: {
      handler(val) {
        if (val) {
          this.projectNameList = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },

    locationData: {
      handler(val) {
        if (val) {
          this.form.location = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },

    timeData: {
      handler(val) {
        if (val) {
          this.time = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 生命周期-实例创建完成后
  async created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .input-with-select /deep/.el-input-group__prepend {
    background-color: #fff;
  }

  /deep/.el-cascader,
  /deep/.el-date-editor {
    width: 100%;
  }
}
</style>
