<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="业绩名称">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入业绩名称关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标时间">
            <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="金额">
            <div class="flex alc">
              <el-input
                v-model.number.trim="form.amountMin"
                placeholder="请输入最小值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>

              <span class="ml10 mr10">至</span>

              <el-input
                v-model.number.trim="form.amountMax"
                placeholder="请输入最大值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="人员证书" style="margin-bottom: 0px">
        <el-row
          class="mb22"
          v-for="(item, index) in personNoType"
          :key="'project' + index"
        >
          <el-col class="mr20" :span="20">
            <el-cascader
              v-model="item.value"
              :options="personNoTypeOptions"
              filterable
              :props="{
                value: 'allDesc',
                label: 'desc',
                children: 'children',
                emitPath: false,
              }"
              clearable
              style="width: 100%"
              placeholder="请输入关键词搜索"
            ></el-cascader>
          </el-col>

          <el-col :span="2" class="tac">
            <el-button
              v-if="index == 0"
              type="primary"
              icon="el-icon-plus"
              plain
              @click="addPersonNo"
            >
              增加
            </el-button>

            <el-button
              v-else
              @click="delPersonNo(index)"
              type="danger"
              icon="el-icon-delete"
              circle
              plain
            ></el-button>
          </el-col>
        </el-row>
      </el-form-item>

      <div class="tar">
        <el-button @click="cancel"> 取消 </el-button>

        <el-button @click="reset"> 重置 </el-button>

        <el-button type="primary" @click="submit"> 确定 </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
/* 组合查询-添加人员业绩查询项 */
/* 
  formData: 表单数据-从父组件传递过来的参数
  typeData：人员证书数据
  timeData：时间数据
*/

import { sourceTree } from "@/api/common"; // 引入ajax函数
import _ from "lodash"; // 引入lodash插件

export default {
  props: ["formData", "typeData", "timeData"],
  // 数据
  data() {
    return {
      form: {
        name: "", // 业绩名称
        timeStart: "", // 中标时间-开始
        timeEnd: "", // 中标时间-结束
        amountMin: "", // 金额-最小
        amountMax: "", // 金额-最大
        personNoType: "", // 人员证书
      },

      personNoTypeOptions: [], // 人员证书选项数据
      personNoType: [{ value: "" }], // 人员证书数据
      time: [], // 中标时间
      // 快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取树形数据
    async getOptions() {
      const personNoTypeOptions = await sourceTree({
        source: "personnelNoType",
      });
      this.personNoTypeOptions = personNoTypeOptions;
    },

    /* 操作 */
    // 添加人员证书
    addPersonNo() {
      if (this.personNoType.length < 5) {
        this.personNoType.push({
          value: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除人员证书
    delPersonNo(index) {
      this.personNoType.splice(index, 1);
    },
    // 取消
    cancel() {
      this.$emit("cancel");
    },
    // 重置
    reset() {
      this.form = {
        name: "", // 业绩名称
        timeStart: "", // 中标时间-开始
        timeEnd: "", // 中标时间-结束
        amountMin: "", // 金额-最小
        amountMax: "", // 金额-最大
        personNoType: "", // 人员证书
      };
      this.time = [];
      this.personNoType = [
        {
          value: "",
        },
      ];
    },
    // 查询
    submit() {
      const personNoType = this.personNoType
        .filter((v) => v.value != "")
        .map((v) => v.value)
        .join(",");

      const params = {
        ...this.form,
        personNoType,
      };
      let data = {
        params,
        time: this.time,
        personNoType: this.personNoType,
      };

      this.$emit("confirm", data);
    },
    // 初始化
    __init__() {
      this.getOptions();
    },
  },

  // 数据监听
  watch: {
    formData: {
      handler(val) {
        if (val) {
          this.form = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },

    typeData: {
      handler(val) {
        if (val) {
          this.personNoType = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },

    timeData: {
      handler(val) {
        if (val) {
          this.time = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 生命周期-实例创建完成后
  async created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .list {
    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px solid #efefef;
      cursor: pointer;

      .index {
        margin-right: 20px;
        color: #999999;
        font-size: 22px;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 30px;

        .tag {
          font-size: 16px;
          padding: 2px 8px 4px;
          border-radius: 4px;
          line-height: 22px;
          color: #1d89e3;
          background-color: #d7edfb;
        }
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          margin-right: 80px;
          flex: 1;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
        .name {
          color: #1d89e3;
        }
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
