<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="项目地区">
            <el-cascader
              v-model="form.place"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
              style="width: 100%"
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="获奖时间">
            <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="获奖企业">
            <el-input
              v-model.trim="form.company"
              placeholder="请输入获奖企业关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="颁发单位">
            <el-input
              v-model.trim="form.announcedUnits"
              placeholder="请输入颁发单位关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="荣誉奖项">
        <el-row class="flex alc">
          <el-col class="mr30 flex1">
            <el-select
              style="width: 100%"
              v-model="form.honorType"
              placeholder="请选择荣誉奖项"
              @change="handleHonorTypeChange"
              clearable
            >
              <el-option
                v-for="item in honorTypeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>

          <el-col class="flex1">
            <el-select
              v-if="form.honorType"
              style="width: 100%"
              v-model="form.grade"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in gradeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="项目名称" style="margin-bottom: 0px">
        <el-row
          class="flex alc mb22"
          v-for="(item, index) in honorNameList"
          :key="'project' + index"
        >
          <el-col class="mr30 flex1">
            <el-input
              placeholder="请输入关键词"
              v-model.trim="item.keyword"
              class="input-with-select"
              clearable
            >
              <el-select
                style="width: 130px"
                v-model="item.radio"
                slot="prepend"
                placeholder="请选择"
                clearable
              >
                <el-option label="包含" value="包含"></el-option>
                <el-option label="不包含" value="不包含"></el-option>
              </el-select>
            </el-input>
          </el-col>

          <el-col class="flex1">
            <el-button
              v-if="index == 0"
              type="primary"
              icon="el-icon-plus"
              plain
              @click="addName"
            >
              增加
            </el-button>

            <el-button
              v-else
              @click="delName(index)"
              type="danger"
              icon="el-icon-delete"
              circle
              plain
            ></el-button>
          </el-col>
        </el-row>
      </el-form-item>

      <div class="tar">
        <el-button @click="cancel"> 取消 </el-button>

        <el-button @click="reset"> 重置 </el-button>

        <el-button type="primary" @click="submit"> 确定 </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
/* 组合查询-添加人员业绩查询项 */
/* 
  formData: 表单数据-从父组件传递过来的参数
  nameList：荣誉名称数据
  timeData：时间数据
*/

import { getDict } from "@/api/common"; // 引入ajax函数
import area from "@/utils/area"; // 引入区域json
import _ from "lodash"; // 引入lodash插件

export default {
  props: ["formData", "nameList", "timeData", "placeData"],
  // 数据
  data() {
    return {
      form: {
        place: [], // 地区
        timeMin: "", // 获奖时间-最小值
        timeMax: "", // 获奖时间-最大值
        company: "", // 获奖企业
        announcedUnits: "", // 颁发单位
        honorType: "", // 数据字典：honorType
        honorTypeCn: "", // 数据字典：honorType
        grade: "", // 数据字典：honorGrade
        nameIncludeList: [], // 荣誉名称-包含
        nameExcludeList: [], // 荣誉名称-不包含
      },

      time: [], // 时间
      honorTypeOptions: [], // 荣誉奖项下拉数据
      gradeOptions: [], // 荣誉奖项下拉数据
      // 荣誉名称
      honorNameList: [
        {
          radio: "",
          keyword: "",
        },
      ],

      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取下拉选项数据
    async getOptions() {
      // 荣誉奖项-类型
      const honorTypeOptions = await getDict({ source: "honorType" });
      this.honorTypeOptions = honorTypeOptions;
      // 荣誉奖项-等级
      const gradeOptions = await getDict({ source: "honorGrade" });
      this.gradeOptions = gradeOptions;
    },

    /* 操作 */
    // 荣誉奖项change事件
    handleHonorTypeChange(val) {
      let honorTypeCn = "";
      if (val) {
        honorTypeCn = this.honorTypeOptions
          .filter((v) => v.value == val)
          .map((v) => v.name)[0];
      }
      this.form.honorTypeCn = honorTypeCn;

      if (!val) {
        this.form.grade = "";
      }
    },
    // 添加项目名称
    addName() {
      if (this.honorNameList.length < 5) {
        this.honorNameList.push({
          radio: "",
          keyword: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除项目名称
    delName(index) {
      this.honorNameList.splice(index, 1);
    },
    // 重置
    reset() {
      this.form = {
        place: [], // 地区
        timeMin: "", // 获奖时间-最小值
        timeMax: "", // 获奖时间-最大值
        company: "", // 获奖企业
        announcedUnits: "", // 颁发单位
        honorType: "", // 数据字典：honorType
        grade: "", // 数据字典：honorGrade
        nameIncludeList: [], // 荣誉名称-包含
        nameExcludeList: [], // 荣誉名称-不包含
        pageNum: 1,
        pageSize: 20,
      };
      this.time = [];
      this.honorNameList = [
        {
          radio: "",
          keyword: "",
        },
      ];
    },
    // 取消
    cancel() {
      this.$emit("cancel");
    },
    // 查询
    submit() {
      const place = this.form.place.filter((v) => v != "").join("-");
      let timeMin = "";
      let timeMax = "";
      if (this.time.length > 0) {
        timeMin = this.time[0];
        timeMax = this.time[1];
      }
      const nameIncludeList = this.honorNameList
        .filter((v) => v.radio == "包含" && v.keyword)
        .map((v) => v.keyword);
      const nameExcludeList = this.honorNameList
        .filter((v) => v.radio == "不包含" && v.keyword)
        .map((v) => v.keyword);

      const params = {
        ...this.form,
        place,
        timeMin,
        timeMax,
        nameIncludeList,
        nameExcludeList,
      };
      let data = {
        params,
        place: this.form.place,
        time: this.time,
        honorNameList: this.honorNameList,
      };

      this.$emit("confirm", data);
    },
    // 初始化
    __init__() {
      this.areaOptions = area;
      this.getOptions();
    },
  },

  // 数据监听
  watch: {
    formData: {
      handler(val) {
        if (val) {
          this.form = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },

    nameList: {
      handler(val) {
        if (val) {
          this.honorNameList = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },

    timeData: {
      handler(val) {
        if (val) {
          this.time = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },

    placeData: {
      handler(val) {
        if (val) {
          this.form.place = _.cloneDeep(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 生命周期-实例创建完成后
  async created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .list {
    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px solid #efefef;
      cursor: pointer;

      .index {
        margin-right: 20px;
        color: #999999;
        font-size: 22px;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 30px;

        .tag {
          font-size: 16px;
          padding: 2px 8px 4px;
          border-radius: 4px;
          line-height: 22px;
          color: #1d89e3;
          background-color: #d7edfb;
        }
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          margin-right: 80px;
          flex: 1;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
        .name {
          color: #1d89e3;
        }
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
